import {
  BrowserRouter as Router,
  Routes,
  Route,
  //Link
} from "react-router-dom";
import FramerClaudio from "./pages/FramerClaudio";

function App() {
  return (
    <div className="App">
      <section>
      <h1>
      Designing for impact that truly matters. 
      </h1>
      <p>Designing since 2016 for various industry and company scales, I believe design should be meaningful, as a means to happy customers for a successful business. Combining a technical background in computer science with focus on delivering impactful design solutions since 2016. My experience with different startup scale in digital media, e-commerce, and finance provides me with diverse perspectives and comprehensive design approaches to tackle various challenges.</p>
      </section>
       <Router>
          <Routes>
          <Route path="/" element={<FramerClaudio/>}></Route>
          </Routes>
        </Router>
    </div>
  );
}

export default App;
